import { FC, useState, useEffect } from "react";
import { BlockContainer } from "src/atoms";
import Renderer from "src/lib/renderer";
import {
  AspectRatio,
  Box,
  Grid,
  GridItem,
  Button,
  Text,
  keyframes,
  useBreakpointValue,
} from "@chakra-ui/react";
import { clamp } from "../utils";
import { useInView } from "react-intersection-observer";
import { isIOS, isSafari } from "react-device-detect";
import { DEFAULT_ASPECT_RATIO, DEFAULT_DURATION, DESKTOP_SECTION_WIDTH } from "./constants";

const progressBarFillingIn = keyframes({
  from: {
    width: "0"
  },

  to: {
    width: '100%'
  }
});

const progressBarFillingOut = keyframes({
  from: {
    width: "100%"
  },

  to: {
    width: '0'
  }
});

const BlockAnimation: FC<Gatsby.PageBlockAnimationFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();
  if (!items) throw Error();

  if ((items?.length ?? 0) <= 0) {
    throw Error();
  }

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const {
    content
  } = primary;

  const [itemIndex, setItemIndex] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const numberItems = items?.length || 0;

  const desktopVideoMP4 = items[itemIndex]?.video_mp4?.url;
  const desktopVideoWebM = items[itemIndex]?.video_webm?.url;
  const mobileVideoMP4 = items[itemIndex]?.mobile_video_mp4?.url;
  const mobileVideoWebM = items[itemIndex]?.mobile_video_webm?.url;

  const desktopPlaceholderImageUrl = items[itemIndex]?.placeholder_image?.document?.data?.image_file?.url;
  // TODO: LATER: take it into account while ensuring only one placeholder gets loaded per platform
  const mobilePlaceholderImageUrl = items[itemIndex]?.mobile_placeholder_image?.document?.data?.image_file?.url;

  const aspectRatio = items[itemIndex]?.aspect_ratio || DEFAULT_ASPECT_RATIO;
  const duration = clamp(0, Number.MAX_SAFE_INTEGER, DEFAULT_DURATION, items[itemIndex]?.duration);

  // @see https://www.reddit.com/r/webdev/comments/1dpoxu6/webm_playback_is_broken_in_safari_175/
  const forceMP4 = isIOS || isSafari;

  useEffect(() => {
    if (typeof window !== "undefined") {
      const videoElement = document.getElementById('mobile-video') as HTMLVideoElement;
      const videoMP4Element = document.getElementById("mobile-video-mp4");
      const videoWebMElement = document.getElementById("mobile-video-webm");
      if (videoElement) {
        videoElement.pause();

        if (videoMP4Element && mobileVideoMP4) {
          videoMP4Element.setAttribute("src", mobileVideoMP4);
        }

        if (videoWebMElement && mobileVideoWebM) {
          videoWebMElement.setAttribute("src", mobileVideoWebM);
        }

        videoElement.load();
      }
    }
  }, [mobileVideoMP4, mobileVideoWebM]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const videoElement = document.getElementById('desktop-video') as HTMLVideoElement;
      const videoMP4Element = document.getElementById("desktop-video-mp4");
      const videoWebMElement = document.getElementById("desktop-video-webm");
      if (videoElement) {
        videoElement.pause();

        if (videoMP4Element && desktopVideoMP4) {
          videoMP4Element.setAttribute("src", desktopVideoMP4);
        }

        if (videoWebMElement && desktopVideoWebM) {
          videoWebMElement.setAttribute("src", desktopVideoWebM);
        }

        videoElement.load();
      }
    }
  }, [desktopVideoMP4, desktopVideoWebM]);

  useEffect(() => {
    if (!isMounted || !isMobile) return;

    setTimeout(() => {
      setItemIndex((itemIndex) => (itemIndex + 1) % numberItems);
    }, duration);
  }, [itemIndex, isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const inViewOptions = {
    rootMargin: "50px 0px 50px 0px",
    triggerOnce: true,
  };

  const { ref: desktopRef, inView: desktopInView } = useInView(inViewOptions);
  const { ref: mobileRef, inView: mobileInView } = useInView(inViewOptions);
  const inView = desktopInView || mobileInView;

  return <BlockContainer>
    <Renderer
      field={content}
    />
    <Grid
      gridRow={1}
      gridTemplateColumns={{
        base: "auto",
        md: `${DESKTOP_SECTION_WIDTH}px auto`
      }}
      gridRowGap={{
        base: "space-24",
        md: 0
      }}
      gridColumnGap={{
        base: 0,
        md: "52px"
      }}
      w="full"
    >
      <GridItem
        minWidth={{ base: "full", md: `${DESKTOP_SECTION_WIDTH}px` }}
        maxWidth={{ base: "full", md: `${DESKTOP_SECTION_WIDTH}px` }}
      >{items.map((item, index) => {
        if (!item) {
          return null;
        }

        const progressBarRemainingWidthProps = itemIndex !== index ? {
          width: "100%"
        } : {};

        const {
          title,
          description
        } = item;

        return (
          <Box
            marginTop={index === 0 ? "0" : "8px"}
            marginBottom="0px !important"
            fontFamily="body"
            fontWeight="normal"
            color={itemIndex === index ? "grey-0" : "#CDCDCD"}
            key={`${title}-${description}`}
          >
            <Button
              w="full"
              display={{
                base: itemIndex === index ? "inline-flex" : "none",
                md: "inline-flex"
              }}
              fontSize="font-20"
              lineHeight="base"
              fontWeight="normal"
              justifyContent="left"
              variant="ghost"
              paddingX="0"
              paddingTop="0 !important"
              paddingBottom={itemIndex === index ? "space-16" : "0 !important"}
              backgroundColor="white"
              _active={{
                color: "grey-0"
              }}
              _hover={{
                backgroundColor: "white",
              }}
              onClick={() => {
                setItemIndex(index)
              }
              }
            >
              {title}
            </Button>
            {itemIndex === index ? <Text
              marginBottom="space-4"
              fontSize="font-16"
              lineHeight="short"
              textAlign="left"
              maxWidth={{ base: "full", md: "70%" }}
            >
              {description}
            </Text> : null}
            {itemIndex === index ? <Box
              display={{
                base: itemIndex === index ? "inline-block" : "none",
                md: "inline-block"
              }}
              animation={inView ? `${progressBarFillingIn} ${duration}ms linear infinite` : undefined}
              borderTop="1px solid"
              borderColor={"grey-0"}
            /> : null}
            <Box
              animation={itemIndex === index && inView ? `${progressBarFillingOut} ${duration}ms linear infinite` : undefined}
              {...progressBarRemainingWidthProps}
              borderTop="1px solid"
              display={{
                base: itemIndex === index ? "inline-block" : "none",
                md: "inline-block"
              }}
              borderColor={"#CDCDCD"}
            />
          </Box>
        )
      })}
      </GridItem>
      <GridItem
        display={{ base: "none", md: "block" }}
        ref={desktopRef}
      >
        {desktopVideoMP4 || desktopVideoWebM ? <AspectRatio
          width="100%"
          ratio={aspectRatio}
          overflow="hidden"
          borderRadius="lg"
          objectFit="cover"
        >
          {desktopVideoMP4 || desktopVideoWebM
            ? (<video id="desktop-video" poster={desktopPlaceholderImageUrl} autoPlay loop muted playsInline>
              {desktopInView && desktopVideoWebM && !forceMP4 ? <source id="desktop-video-webm" src={desktopVideoWebM} type="video/webm" /> : null}
              {desktopInView && desktopVideoMP4 ? <source id="desktop-video-mp4" src={desktopVideoMP4} type="video/mp4" /> : null}
            </video>) : null}
        </AspectRatio> : null}
      </GridItem>
      <GridItem
        display={{ base: "block", md: "none" }}
        ref={mobileRef}
      >
        {mobileVideoMP4 || mobileVideoWebM ? <AspectRatio
          width="100%"
          ratio={aspectRatio}
          overflow="hidden"
          borderRadius="lg"
          objectFit="cover"
        >
          {mobileVideoMP4 || mobileVideoWebM
            ? (<video id="mobile-video" poster={desktopPlaceholderImageUrl} autoPlay loop muted playsInline>
              {mobileInView && mobileVideoWebM && !forceMP4 ? <source id="mobile-video-webm" src={mobileVideoWebM} type="video/webm" /> : null}
              {mobileInView && mobileVideoMP4 ? <source id="mobile-video-mp4" src={mobileVideoMP4} type="video/mp4" /> : null}
            </video>) : null}
        </AspectRatio> : null}
      </GridItem>
    </Grid>
  </BlockContainer>;
};

export default BlockAnimation;
