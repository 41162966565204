import { VStack } from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { getBackgroundColor, checkExtraPadding, getSliceType, isEmbedHubspotFormSlice, isHubspotFormSlice } from "./utils";
import { Slice } from "./types";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";

type Slices = Readonly<Array<Gatsby.Maybe<Slice>>>;

const HEADER_TYPES = [
  "header",
  "header_spotlight",
  "block_embed_hubspot_form",
  "block_hoverable_cards"
];

//prevBlock and nextBlock prop is required for determining the spacing when SliceZone component is used
//inside of the block relation

const SliceZone: FC<{
  body?: Slices;
  section?: string;
  slices: {
    [key: string]: FC<any>;
  };
  is_block_relation?: boolean;
  prevBlock?: Slice;
  nextBlock?: Slice;
}> = ({ body, slices, section, is_block_relation, prevBlock, nextBlock }) => {
  const hasHubspotForms = body?.some((slice) => isHubspotFormSlice(slice));
  const hasEmbedHubspotForms = body?.some((slice) => isEmbedHubspotFormSlice(slice));
  const Wrapper = hasHubspotForms ? HubspotProvider : Fragment;
  
  return <Wrapper>
    {body?.map((slice, index) => {
      if (!slice || !slices[slice.slice_type]) {
        console.warn(`This slice is not recognized and will be ignored`, slice);
        return null;
      }

      if (!slice.primary && !slice.items) return null;

      const SliceComponent = slices[slice.slice_type];

      const currentSlice = getSliceType(slice);

      const background = getBackgroundColor(currentSlice);

      //if the block is the first block inside block relation,
      //previous block of the page slices will be used as a previous block
      const prevSlice =
        is_block_relation && index === 0 && prevBlock
          ? prevBlock
          : getSliceType(body[index - 1]);

      //if the block is the last block inside block relation,
      //next block of the page slices will be used as a next block
      const nextSlice =
        is_block_relation && index === body.length - 1 && nextBlock
          ? nextBlock
          : getSliceType(body[index + 1]);

      const isHeader = HEADER_TYPES.includes(slice.slice_type!);
      const isHubspotForm = isHubspotFormSlice(slice);
      const additionalProps = isHubspotForm ? { index } : {};

      const isLast = (!is_block_relation && index === body.length - 1 ) || (is_block_relation && !nextBlock)
      const extraPaddingPreviousSlice = checkExtraPadding(currentSlice, prevSlice, is_block_relation);
      const extraPaddingNextSlice = checkExtraPadding(currentSlice, nextSlice, is_block_relation);
      const marginTopProps = hasEmbedHubspotForms && slice.slice_type === 'block_columns'
        ? { marginTop: "0px !important" }
        : {};

      return (
        <VStack
          spacing="0"
          as="section"
          key={slice.id}
          width="100%"
          bg={background || "transparent"}
          pt={{
            base:
              !isHeader && extraPaddingPreviousSlice
                ? "space-40"
                : 0,
            md:
              !isHeader && extraPaddingPreviousSlice
                ? "space-60"
                : 0,
          }}
          pb={{
            base: extraPaddingNextSlice && !isLast
              ? "space-40"
              : 0,
            md: extraPaddingNextSlice && !isLast
              ? "space-60"
              : 0,
          }}
          marginBottom={
            isLast && background
              ? { base: "40px !important", md: "60px !important" } //important is required to overwrite stack's children margin rule
              : undefined
          }
          {...marginTopProps}
        >
          <SliceComponent
            {...slice}
            section={section}
            prevBlock={prevSlice}
            nextBlock={nextSlice}
            {...additionalProps}
          />
        </VStack>
      );
    })}
  </Wrapper>
};

export default SliceZone;
