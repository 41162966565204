import { FC, useState, Fragment } from "react";
import { BlockContainer } from "src/atoms";
import { GatsbyImage } from "gatsby-plugin-image";
import Renderer from "src/lib/renderer";
import { heading1 } from "src/lib/renderer/elements/headings";
import paragraph from "src/lib/renderer/elements/paragraph";
import { Element } from "@prismicio/react";
import { PrismicLink } from "src/atoms";
import { hyperlink } from "src/lib/renderer/elements/hyperlink";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Spacer,
  Text,
  VStack,
  Button,
  useBreakpointValue
} from "@chakra-ui/react";

const MAX_INDEX_IMAGE = 2;

const BlockHoverableCards: FC<Gatsby.PageBlockHoverableCardsFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();

  const {
    content,
    side_content_1_text: sideContent1Text,
    side_content_1_label: sideContent1Label,
    side_content_1_link: sideContent1Link,
    side_content_1_image_1: sideContent1Image1,
    side_content_1_image_2: sideContent1Image2,
    side_content_1_image_3: sideContent1Image3,
    side_content_2_text: sideContent2Text,
    side_content_2_label: sideContent2Label,
    side_content_2_link: sideContent2Link,
    side_content_2_image_1: sideContent2Image1,
    side_content_2_image_2: sideContent2Image2,
    side_content_2_image_3: sideContent2Image3,
  } = primary;

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const [expandedItemsIndexes, setExpandedItemsIndexes] = useState<number[]>([]);
  const toggleItemsIndexes = (index: number) => {
    setExpandedItemsIndexes(expandedItemsIndexes => {
      const newExpandedItemsIndexes = expandedItemsIndexes.includes(index)
        ? expandedItemsIndexes.filter((i: number) => i !== index)
        : [...expandedItemsIndexes, index];

      return newExpandedItemsIndexes
    })
  }

  const setItemsIndexes = (index: number) => {
    const newExpandedItemsIndexes = [index];
    setExpandedItemsIndexes(newExpandedItemsIndexes);
  };

  const clearItemsIndexes = () => {
    setExpandedItemsIndexes([]);
  };

  const renderSideContentText = (text?: string) => {
    if (!text) {
      return null;
    }

    return (
      <Text
        color="greyLight-0"
        fontSize="font-14"
        lineHeight="shorter"
        fontWeight="400"
      >
        {text}
      </Text>
    );
  }

  const renderSideContentLink = (sideContentLabel?: string, sideContentLink?: Gatsby.Maybe<Gatsby.LinkFragment>) => {
    if (!sideContentLabel || !sideContentLink) {
      return null;
    }

    return (
      <PrismicLink
        key={sideContentLabel}
        link={sideContentLink}
        color="#2A93F4"
        fontSize="font-15"
        lineHeight="base"
        fontWeight="500"
        pb="space-2"
        borderBottom="1px solid"
      >
        {sideContentLabel}
      </PrismicLink>
    )
  };

  const renderItem = (item: NonNullable<Gatsby.PageBlockHoverableCardsFragment["items"]>[number], index: number) => {
    const isLarge = index === 0;
    const widthProp = isLarge ? { width: { base: "100%", lg: "350px", xl: "426px" } } : { width: "inherit" };
    const titleProp = isLarge ? { maxWidth: { base: "100%", md: "90%", lg: "70%" } } : { maxWidth: { base: "100%", md: "100%", "2xl": "95%" } };

    if (!item) {
      return null;
    }

    const {
      title,
      description,
      background_image: backgroundImage,
      link
    } = item;

    const backgroundImageUrl = backgroundImage?.document?.data?.image_file?.gatsbyImageData?.images?.fallback?.src;
    const isExpanded = expandedItemsIndexes.includes(index);
    const boxShadowOpacity = isExpanded ? 1 : 0;
    const hasLink = !!link && link.link_type !== "Any";
    const Wrapper = hasLink ? PrismicLink : Fragment;
    const wrapperProps = hasLink ? {
      link,
    } : {};

    return (
      <GridItem
        {...widthProp}
        key={`${title}-${description}-${backgroundImageUrl}`}
        boxShadow={`inset 0 0 0 2000px rgba(37, 36, 29, ${boxShadowOpacity})`}
        backgroundImage={`url('${backgroundImageUrl}')`}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition={{ base: "center 22%", md: "center center" }}
        transition="box-shadow 200ms ease-in"
        borderTopRadius="lg"
        height={{ base: "398px", lg: "480px" }}
        borderBottomRadius="lg"
        marginTop={{ base: "0", md: "space-40" }}
        onMouseEnter={() => { if (!isMobile) setItemsIndexes(index) }}
        onMouseLeave={() => { if (!isMobile) clearItemsIndexes() }}
      >
        <Box
          display={{ base: "block", sm: "none" }}
          position="absolute"
          marginTop="space-32"
          right="space-48"
        >
          <Button
            bg="yellow-0"
            _disabled={{
              bg: "yellow-0",
              color: "text-primary"
            }}
            _hover={{
              bg: "yellow-0",
              color: "text-primary"
            }}
            paddingY="space-16"
            paddingX="space-24"
            minWidth="56px"
            maxWidth="56px"
            minHeight="56px"
            maxHeight="56px"
            borderRadius="80px"
            position="relative"
            color="grey-0"
            onClick={() => toggleItemsIndexes(index)}
          >
            <Text
              position="absolute"
              left="50%"
              transform="translate(-50%, -50%)"
              top="50%"
              fontSize="font-18"
              fontWeight="500"
              lineHeight="base"
            >
              {isExpanded ? "-" : "+"}
            </Text>
          </Button>
        </Box>
        <Wrapper
          {...wrapperProps}
        >
          <Flex
            w="full"
            bg="linear-gradient(to top, #25241D00 70%, #25241D80 100%)"
            borderTopRadius="lg"
            flexDirection="column"
            height={{ base: "398px", lg: "480px" }}
          >
            <Flex
              flexDirection="row"
            >
              <Box
                padding="space-32"
                color="white"
                fontSize="font-20"
                lineHeight="base"
                textAlign="left"
                w={{ base: "calc(100% - 56px - 8px)", md: "full" }}
              >
                <Text
                  {...titleProp}
                >
                  {title}
                </Text>
              </Box>
            </Flex>
            <Spacer
              display={isExpanded ? "block" : "none"}
            />
            <Text
              display={isExpanded ? "block" : "none"}
              textAlign="left"
              paddingY={{ base: "space-32", md: "space-24" }}
              paddingX="space-32"
              color={{ base: "greyLight-1", md: "white" }}
              fontSize="font-16"
              lineHeight="short"
            >
              {description}
            </Text>
          </Flex>
        </Wrapper>
      </GridItem>
    );
  }

  const renderImg = (image: Gatsby.Maybe<{
    readonly document: Gatsby.Maybe<Gatsby.ElementImageFixed40Fragment>;
  }>, index: number, shouldOverlap = false, includeMarginRight = false) => {
    const imageFile = image?.document?.data?.image_file;
    if (!imageFile) {
      return null;
    }

    return (
      <Box
        display="inline-block"
        as={GatsbyImage}
        image={imageFile.gatsbyImageData}
        alt={imageFile.alt ?? ""}
        marginRight={shouldOverlap && !includeMarginRight ? "-16px" : "0"}
        zIndex={typeof index !== "undefined" ? MAX_INDEX_IMAGE - index : "inherit"}
      />
    );
  };

  return <BlockContainer paddingTop="24px !important">
    <VStack w="full">
      <Flex
        w="full"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Box
          maxWidth={{ base: "full", md: "50%" }}
          textAlign="left"
        >
          <Renderer
            field={content}
            overrides={{
              [Element.heading1]: (args) =>
                heading1({
                  ...args,
                  overrideProps: {
                    lineHeight: "1"
                  },
                }),
              [Element.paragraph]: (args) =>
                paragraph({
                  ...args,
                  overrideProps: {
                    fontSize: "font-20",
                    lineHeight: "base"
                  },
                }),
              [Element.hyperlink]: (args) => {
                return hyperlink({
                  ...args,
                  hasArrow: false,
                  overrideProps: {
                    fontWeight: "normal",
                    fontSize: { base: "font-18", md: "font-15" },
                    lineHeight: { base: 1.55, md: "base" },
                    minWidth: { base: "full", md: "inherit" },
                    marginBottom: { base: "18px", md: "0" }
                  }
                })
              }
            }}
          />
        </Box>
        <Spacer
          display={{ base: "none", md: "block" }}
        />
        <Grid
          gridTemplateColumns={{ base: "auto auto", md: "auto auto" }}
          gridColumnGap={"space-12"}
          height={{ base: "inherit", md: "0" }}
          alignItems={{ base: "flex-end", md: "flex-start" }}
          marginBottom={{ base: "space-80", md: "inherit" }}
        >
          <GridItem
            display={{ base: "block", md: "none" }}
            textAlign="left"
          >
            <Box
              marginBottom="space-8"
            >
              {renderImg(sideContent1Image1, 0, true, !sideContent1Image2 && !sideContent1Image3)}
              {renderImg(sideContent1Image2, 1, true, !sideContent1Image3)}
              {renderImg(sideContent1Image3, 2, true, true)}
            </Box>
          </GridItem>

          <GridItem
            display={{ base: "none", md: "block" }}
            textAlign="right"
          >
            {renderImg(sideContent1Image1, 0, true, !sideContent1Image2 && !sideContent1Image3)}
            {renderImg(sideContent1Image2, 1, true, !sideContent1Image3)}
            {renderImg(sideContent1Image3, 2, true, true)}
          </GridItem>
          <GridItem
            textAlign="left"
            display={{ base: "none", md: "inline-block" }}
          >
            {renderSideContentText(sideContent1Text)}
            {renderSideContentLink(sideContent1Label, sideContent1Link)}
          </GridItem>

          <GridItem
            display={{ base: "block", md: "none" }}
            textAlign="left"
          >
            <Box>
              {renderImg(sideContent2Image1, 0)}
              {renderImg(sideContent2Image2, 1)}
              {renderImg(sideContent2Image3, 2)}
            </Box>
          </GridItem>

          <GridItem
            display={{ base: "block", md: "none" }}
            textAlign="left"
          >
            {renderSideContentText(sideContent1Text)}
            {renderSideContentLink(sideContent1Label, sideContent1Link)}
          </GridItem>

          <GridItem
            display={{ base: "block", md: "none" }}
            textAlign="left"
          >
            {renderSideContentText(sideContent2Text)}
            {renderSideContentLink(sideContent2Label, sideContent2Link)}
          </GridItem>

          <GridItem
            textAlign="right"
            marginTop="space-24"
            display={{ base: "none", md: "block" }}
          >
            {renderImg(sideContent2Image1, 0)}
            {renderImg(sideContent2Image2, 1)}
            {renderImg(sideContent2Image3, 2)}
          </GridItem>
          <GridItem
            textAlign="left"
            marginTop="space-24"
            display={{ base: "none", md: "inline-block" }}
          >
            {renderSideContentText(sideContent2Text)}
            {renderSideContentLink(sideContent2Label, sideContent2Link)}
          </GridItem>
        </Grid>
      </Flex>
      <Grid
        w='full'
        marginTop={{ base: "0 !important", md: "inherit" }}
        gridTemplateColumns={{ base: "1", md: "repeat(2, 1fr)", lg: "auto repeat(3, 1fr)" }}
        gridTemplateRows={{ base: "auto", md: "2", lg: "1" }}
        gridGap="space-16"
      >
        {items?.length ? items.map((item, index: number) => {
          if (!item) {
            return null;
          }

          return renderItem(item, index);
        }) : null}
      </Grid>
    </VStack>
  </BlockContainer>
};

export default BlockHoverableCards;
