import { ESort } from "..";

const sortItems = (items: Gatsby.PageBlockFilterableListFragment["items"], sort: ESort) => {
  if (!items || items.length === 0) {
    return [];
  }

  if (sort === ESort.Trending) {
    return items;
  }

  const now = new Date();
  return [...items].sort((a, b) => {
    if (!a || !b) {
      return 0;
    }

    const aDate = new Date(a.created_at || now);
    const bDate = new Date(b.created_at || now);

    return aDate > bDate ? 1 : -1;
  });
}

export default sortItems;
