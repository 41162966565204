import { ESort } from "..";
import getCategories from "./getCategories";
import sortItems from "./sortItems";

const getFilteredItems = (
  items: Gatsby.PageBlockFilterableListFragment["items"],
  categoriesArray: string[],
  indexSelectedCategory: number,
  indexAllCategories: number,
  sort: ESort,
  search: string
) => {
  if (!items || items.length === 0) {
    return [];
  }

  const searchFields = (item: Gatsby.PageBlockFilterableListFragment["items"][number]) => {
    const primaryText = item.primary_text?.trim().toLowerCase();
    const tags = item.tags?.raw?.map((tag: any) => tag?.text.trim().toLowerCase()).join(" ");

    return primaryText.includes(search.toLowerCase()) || tags.includes(search.toLowerCase());
  }

  const selectedCategory = categoriesArray[indexSelectedCategory];
  const isAllCategories = indexSelectedCategory === indexAllCategories;

  if (isAllCategories) {
    const allItems = items.filter((item) => {
      return !!item && !item.hidden && (search != "" ? searchFields(item) : true);
    });

    return sortItems(allItems, sort);
  }

  const filteredItems = items.filter((item) => {
    const itemCategories = getCategories(item?.categories);
    const itemIsInSelectedCategory = itemCategories.includes(selectedCategory);

    return !item?.hidden && itemIsInSelectedCategory && (search != "" ? searchFields(item) : true);
  });


  return sortItems(filteredItems, sort);
};

export default getFilteredItems;
