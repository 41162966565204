import { ARBITRARY_INITIAL_MOBILE_ITEMS_OFFSET, MAX_DESKTOP_ITEM_WIDTH, MIN_DESKTOP_ITEM_WIDTH, MOBILE_ICON_ITEM_WIDTH, MOBILE_RATING_ITEM_WIDTH, SPACE_BETWEEN_ITEMS } from "./constants";

const getColor = (color?: string) => {
  switch (color) {
    case "grey":
    default:
      return "grey-4";
    case "blue":
      return "blue-1";
    case "green":
      return "#A4D36E";
    case "yellow":
      return "yellow-0";
  }
};

const getMobileItemWidth = (isIcon?: boolean, rating?: number) => {
  if (isIcon) {
    return MOBILE_ICON_ITEM_WIDTH;
  }

  if (rating) {
    return MOBILE_RATING_ITEM_WIDTH;
  }

  return MAX_DESKTOP_ITEM_WIDTH;
};

const getItemWidthProps = (isIcon?: boolean, rating?: number) => {
  const widthProps = {
    base: `${getMobileItemWidth(isIcon, rating)}px`,
    md: `${MIN_DESKTOP_ITEM_WIDTH}px`,
    lg: `${MAX_DESKTOP_ITEM_WIDTH}px`
  };

  return { minWidth: widthProps, maxWidth: widthProps };
};

const calculateMobileItemsWidth = (items: Gatsby.PageBlockScrollableCardsFragment["items"]) => {
  const numberItems = (items ?? []).length;

  if (numberItems === 0) {
    return 0;
  }

  const internalSpaceWidth = (numberItems - 1) * 2 * SPACE_BETWEEN_ITEMS;
  const externalSpaceWidth = 2 * SPACE_BETWEEN_ITEMS;

  const itemsWidth = (items ?? []).reduce(
    (accumulator, currentItem) => {
      if (!currentItem) {
        return accumulator;
      }

      const { is_icon: isIcon, rating } = currentItem;
      return accumulator + getMobileItemWidth(isIcon, rating);
    },
    0
  );

  const total = externalSpaceWidth + itemsWidth + internalSpaceWidth;
  return total;
};

/**
 * This function is used for calculating the position of each item group for mobile animation.
 * It is intended to be dynamic.
 */
const calculateMarginLeftProps = (
  items: Gatsby.PageBlockScrollableCardsFragment["items"],
  isFirstGroup: boolean,
  isInView: boolean
) => {
  if (!isInView) {
    return {
      marginLeft: `-${ARBITRARY_INITIAL_MOBILE_ITEMS_OFFSET}px`
    }
  };

  if (!isFirstGroup) {
    return {
      marginLeft: "4px"
    }
  }
  
  return {
    marginLeft: `calc((${calculateMobileItemsWidth(items)}px - 100%) * -1 - 4px)`
  };
}

/**
 * This function is used for calculating the position of each item group for desktop animation.
 * It is hardcoded for now because of time constraints, for a first item group of 3 items, and a second item group of 4 items.
 * It is based on the desktop container height (constant 688px) and each item height (320px).
 * The intention is to have alignment between each group (but not necessarily with the container as per the design).
 */
const calculateHardcodedTopValue = (isFirstGroup: boolean, isInView: boolean, isAtLeast2Xl: boolean, isAtLeastLg: boolean) => {
  if (isAtLeast2Xl) {
    // Not having the offset would lead to a clean alignment for the !isInView scenario
    if (isFirstGroup) {
      return isInView ? 16 : -217; // Second value is -152 minus a shared offset of 65
    }

    return isInView ? -488 : -49; // Second value is 16 minus a shared offset of 65
  }

  if (isAtLeastLg) {
    if (isFirstGroup) {
      return isInView ? 16 : -170;
    }
  
    return isInView ? -408 : -29;
  }

  if (isFirstGroup) {
    return isInView ? 16 : -140;
  }

  return isInView ? -348 : -19;
};

export { calculateHardcodedTopValue, calculateMobileItemsWidth, calculateMarginLeftProps, getColor, getItemWidthProps, getMobileItemWidth };
