import { DESKTOP_EXPANDED_ITEM_WIDTH, MAX_DESKTOP_CONTAINER_WIDTH, MOBILE_ITEM_WIDTH, SPACE_BETWEEN_ITEMS } from "./constants";

const getEqualSize = (numberItems: number, breakpoint: "base" | "lg" | "xl" | "2xl" = "base") => {
  if (["base", "lg", "xl"].includes(breakpoint)) {
    return `calc((100% - ${numberItems - 1} * ${SPACE_BETWEEN_ITEMS}px) / ${numberItems})`;
  }

  return `${(MAX_DESKTOP_CONTAINER_WIDTH - (numberItems - 1) * SPACE_BETWEEN_ITEMS) / numberItems}px`;
};

const getNonExpandedSize = (numberItems: number, breakpoint: "base" | "lg" | "xl" | "2xl" = "base") => {
  if (["base", "lg"].includes(breakpoint)) {
    // This part is hardcoded for 4 items
    const nonExpandedPercentage = breakpoint === "base" ? 20 : 16;
    return `calc((100% - ${numberItems - 1} * ${SPACE_BETWEEN_ITEMS}px) / ${numberItems} * ${nonExpandedPercentage} / ${100 / numberItems})`;
  }

  if (breakpoint === "xl") {
    return `calc((100% - ${numberItems - 1} * ${SPACE_BETWEEN_ITEMS}px - ${DESKTOP_EXPANDED_ITEM_WIDTH}px) / ${numberItems - 1})`;
  }

  return `${Math.floor((MAX_DESKTOP_CONTAINER_WIDTH - DESKTOP_EXPANDED_ITEM_WIDTH - (numberItems - 1) * SPACE_BETWEEN_ITEMS) / (numberItems - 1))}px`;
};

const getExpandedSize = (numberItems: number, breakpoint: "base" | "lg" | "xl" | "2xl" = "base") => {
  // This part is hardcoded for 4 items
  if (["base", "lg"].includes(breakpoint)) {
    const expandedPercentage = breakpoint === "base" ? 40 : 52;
    return `calc((100% - ${numberItems - 1} * ${SPACE_BETWEEN_ITEMS}px) / ${numberItems} * ${expandedPercentage} / ${100 / numberItems})`;
  }

  return `${DESKTOP_EXPANDED_ITEM_WIDTH}px`;
};

const getTemplateColumns = (expandedIndex: number, numberItems: number, breakpoint: "base" | "lg" | "xl" | "2xl" = "base") => {
  const es = getExpandedSize(numberItems, breakpoint);
  const nes = getNonExpandedSize(numberItems, breakpoint);
  const eq = getEqualSize(numberItems, breakpoint);

  if (expandedIndex === -1) {
    return `${eq} ${eq} ${eq} ${eq}`;
  }

  const valuesBefore = [...Array(expandedIndex).keys()].map((_v: number) => nes);
  const valuesAfter = [...Array(numberItems - 1 - expandedIndex).keys()].map((_v: number) => nes);

  const values = [
    ...valuesBefore,
    es,
    ...valuesAfter
  ];

  return values.join(" ");
};

const getMobileLeftScrollValue = (direction: string, numberItems: number, childRefScrollLeft: number, windowInnerWidth: number) => {
  const itemWithSpaceWidth = (MOBILE_ITEM_WIDTH + SPACE_BETWEEN_ITEMS);

  if (direction === "prev") {
    const isTowardsFirst = childRefScrollLeft - itemWithSpaceWidth < 0;

    if (isTowardsFirst) {
      return (childRefScrollLeft === 0 ? 0 : -1 * childRefScrollLeft);
    }

    const left = childRefScrollLeft % itemWithSpaceWidth === 0
      ? -1 * itemWithSpaceWidth
      : (childRefScrollLeft - Math.floor(childRefScrollLeft / itemWithSpaceWidth) * itemWithSpaceWidth) * -1;

    return left;
  }

  const scrollContainerWidth = numberItems * MOBILE_ITEM_WIDTH + (numberItems + 1) * SPACE_BETWEEN_ITEMS;
  const isTowardsLast = childRefScrollLeft + itemWithSpaceWidth + windowInnerWidth > scrollContainerWidth;
  const left = isTowardsLast ? scrollContainerWidth - windowInnerWidth - childRefScrollLeft : itemWithSpaceWidth;

  return left;
}

export { getMobileLeftScrollValue, getTemplateColumns };

