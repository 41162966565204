import { Box, VStack, Heading, Grid } from "@chakra-ui/react";
import { FC } from "react";
import Renderer from "src/lib/renderer";
import { GatsbyImage } from "gatsby-plugin-image";

const DesktopGrid: FC<Gatsby.PageBlockCreationRedirectionFragment> = ({
  items,
}) => {
  if (!items) return null;

  const gridColumns = items.length >= 3 ? 3 : items.length;

  return (
    <Grid
      gridGap="space-32"
      gridTemplateColumns={`repeat(${gridColumns}, minmax(0, 1fr))`}
      display={{ base: "none", md: "grid" }}
    >
      {items.map((item) => {
        if (!item) return null;
        return (
          <VStack
            borderRadius="lg"
            bg="greyLight-3"
            padding="space-32"
            spacing="space-24"
            maxW="330px"
            key={item.title}
            sx={{
              p: {
                color: "text-secondary",
              },
            }}
          >
            <Box>
              <Box
                as={GatsbyImage}
                image={item.image?.document?.data?.image_file?.gatsbyImageData}
                alt={item.image?.document?.data?.image_file?.alt ?? ""}
              />
            </Box>
            <Grid h="full">
              <VStack spacing="space-16">
                <Heading as="div" variant="heading-4" >
                  {item.title}
                </Heading>
                <Renderer field={item.content} />
              </VStack>
              <VStack
                spacing="space-16"
                pt="space-32"
                alignSelf="flex-end"
                sx={{
                  p: {
                    marginBottom: 0,
                  },
                }}
              >
                <Renderer field={item.button} />
              </VStack>
            </Grid>
          </VStack>
        );
      })}
    </Grid>
  );
};

export default DesktopGrid;
