import { Box, BoxProps, Center, Heading, Text } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { formatPrice } from "src/lib/pricing";

export const CardBadge: FC<{ color: string }> = ({ color, children }) => (
  <Center>
    <Text
      as="div"
      backgroundColor={`${color}-4`}
      color={`${color}-0`}
      borderRadius="full"
      paddingY="space-4"
      paddingX="space-16"
      textTransform="uppercase"
      fontSize="font-12"
      fontWeight="medium"
      textAlign="center"
      position="absolute"
      top="-12px"
    >
      {children}
    </Text>
  </Center>
);

export const CardName: FC = ({ children }) => (
  <Heading
    as="h3"
    marginBottom="space-16"
    variant="subtitle-large"
    color="text-secondary"
    fontSize="font-22"
  >
    {children}
  </Heading>
);

export const PriceUnderline: FC<{ color: string } & BoxProps> = ({
  color,
  ...props
}) => (
  <Box
    marginTop="calc(-5px - .33em)"
    height="calc(5px + .1em)"
    bg={color}
    borderRadius="full"
    {...props}
  />
);

export const CardPrice: FC<{
  price: number;
  priceUnit: string;
  color: string;
  priceDetails: ReactNode;
}> = ({ price, priceUnit, priceDetails, color }) => {
  const stringPrice = formatPrice(price);

  return (
    <Box>
      <Box width="fit-content">
        <Text as="div" color="text-primary" fontWeight="medium">
          <Text
            as="span"
            display="inline-block"
            fontSize="font-22"
            marginRight="space-8"
          >
            {stringPrice}
          </Text>

          <Text as="span">{priceUnit}</Text>
        </Text>

        <PriceUnderline color={`${color}-4`} />
      </Box>

      <Text as="div" variant="body-small" marginTop="space-4">
        {priceDetails}
      </Text>
    </Box>
  );
};
