import { VStack, StackProps, Box } from "@chakra-ui/react";
import { FC } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import Renderer from "src/lib/renderer";
import { Element } from "@prismicio/react";
import { heading3 } from "src/lib/renderer/elements/headings";
import paragraph from "src/lib/renderer/elements/paragraph";

const Card: FC<
  StackProps & { item: Gatsby.PageBlockFeatureCarouselItemFragment }
> = ({ item, ...rest }) => {
  if (!item) {
    return null;
  }

  const hasDescription =
    item.rich_description && item.rich_description?.text !== "";
  const hasButton = item.button && item.button?.text !== "";

  return (
    <VStack
      bg="blue-2"
      borderRadius="lg"
      w="full"
      maxW={{ base: "270px", md: "360px" }}
      overflow="hidden"
      spacing="0"
      textAlign="left"
      alignItems="stretch"
      transitionProperty="all"
      transitionDuration="300ms"
      transitionTimingFunction="ease-out"
      _hover={{
        transform: { md: "translateY(-8px)" },
      }}
      sx={{
        img: {
          borderTopRadius: "lg", //required to override gatsby image default styling
          overflow: "hidden", //required to override gatsby image default styling
        },
      }}
      {...rest}
    >
      {item?.image?.document?.__typename === "PrismicElementImage" && (
        <Box
          as={GatsbyImage}
          image={item.image?.document?.data?.image_file?.gatsbyImageData}
          alt={item.image?.document?.data?.image_file?.alt ?? ""}
          h={{ base: "156px", md: "240px" }}
        />
      )}
      {(hasDescription || hasButton) && (
        <VStack
          textAlign="left"
          alignItems="stretch"
          padding={{ base: "space-24", md: "space-32" }}
          flex="1"
          spacing={{ base: "space-24", md: "space-32" }}
        >
          {hasDescription ? (
            <VStack
              spacing={{ base: "space-16" }}
              flex="1"
              align="start"
              sx={{
                ".chakra-badge": {
                  fontWeight: "normal",
                },
              }}
            >
              <Renderer
                overrides={{
                  [Element.heading3]: (args) =>
                    heading3({
                      ...args,
                      overrideProps: {
                        marginBottom: 0,
                        variant: "subtitle",
                      },
                    }),
                  [Element.paragraph]: (args) =>
                    paragraph({
                      ...args,
                      overrideProps: {
                        marginBottom: 0,
                      },
                    }),
                }}
                field={item.rich_description}
              />
            </VStack>
          ) : null}
          {hasButton ? (
            <VStack
              spacing="space-24"
              align="start"
              sx={{
                p: {
                  margin: 0,
                },
              }}
            >
              <Renderer field={item.button} />
            </VStack>
          ) : null}
        </VStack>
      )}
    </VStack>
  );
};

export default Card;

