import BlockCardsRedirection from "./BlockCardsRedirection";
import BlockColumns from "./BlockColumns";
import BlockCta from "./BlockCta";
import BlockFaq from "./BlockFaq";
import BlockAccordion from "./BlockAccordion";
import BlockMatrix from "./BlockMatrix";
import BlockRelation from "./BlockRelation";
import Header from "./Header";
import HeaderSpotlight from "./HeaderSpotlight";
import BlockProductRecap from "./BlockProductRecap";
import BlockTestimonial from "./BlockTestimonial";
import BlockHighlightCards from "./BlockHighlightCards";
import BlockTestimonialCarousel from "./BlockTestimonialCarousel";
import BlockPricingDisplay from "./BlockPricingDisplay";
import BlockSteps from "./BlockSteps";
import BlockIllustratedList from "./BlockIllustratedList";
import BlockRichContent from "./BlockRichContent";
import BlockArticlesCarousel from "./BlockArticlesCarousel";
import FloatingBanner from "./FloatingBanner";
import PrebuiltBlock from "./PrebuiltBlock";
import BlockBanner from "./BlockBanner";
import BlockVideo from "./BlockVideo";
import BlockPlansPresentation from "./BlockPlansPresentation";
import BlockCreationPackagePricing from "./BlockCreationPackagePricing";
import BlockPricingTable from "./BlockPricingTable";
import SubPages from "./SubPages"
import BlockTitle from "./BlockTitle"
import BlockSecurity from "./BlockSecurity"
import BlockPersona from "./BlockPersona";
import BlockMission from "./BlockMission";
import BlockCreationRedirection from "./BlockCreationRedirection"
import BlockTrioProductHighlight from "./BlockTrioProductHighlight"
import BlockTetrisProductHighlight from "./BlockTetrisProductHighlight"
import BlockFeatureCarousel from "./BlockFeatureCarousel"
import BlockCenter from "./BlockCenter";
import BlockAnimatedTestimonial from "./BlockAnimatedTestimonial"
import BlockNumber from "./BlockNumber"
import BlockHubspotForm from "./BlockHubspotForm";
import BlockEmbedHubspotForm from "./BlockEmbedHubspotForm";
import BlockFilterableList from "./BlockFilterableList";
import BlockTrioOffer from "./BlockTrioOffer";
import BlockHoverableCards from "./BlockHoverableCards";
import BlockScrollableCards from "./BlockScrollableCards";
import BlockStretchableCards from "./BlockStretchableCards";
import BlockAnimation from "./BlockAnimation";

/**
 * This dictionnary links `slice_type` strings to their respective components.
 */
const SLICES = {
  header: Header,
  header_spotlight: HeaderSpotlight,
  block_columns: BlockColumns,
  block_cta: BlockCta,
  block_features_carousel: BlockAccordion,
  block_relation: BlockRelation,
  block_faq: BlockFaq,
  block_cards_redirection: BlockCardsRedirection,
  block_matrix: BlockMatrix,
  block_product_recap: BlockProductRecap,
  block_testimonial: BlockTestimonial,
  block_highlight_cards: BlockHighlightCards,
  block_testimonial_carousel: BlockTestimonialCarousel,
  block_pricing_display: BlockPricingDisplay,
  block_steps: BlockSteps,
  block_illustrated_list: BlockIllustratedList,
  block_rich_content: BlockRichContent,
  block_articles_carousel: BlockArticlesCarousel,
  prebuilt_block: PrebuiltBlock,
  floating_banner: FloatingBanner,
  block_banner: BlockBanner,
  block_video: BlockVideo,
  block_plans_presentation: BlockPlansPresentation,
  block_creation_package_pricing: BlockCreationPackagePricing,
  block_pricing_table: BlockPricingTable,
  sub_pages: SubPages,
  block_title: BlockTitle,
  block_security: BlockSecurity,
  block_persona: BlockPersona,
  block_mission: BlockMission,
  block_creation_redirection: BlockCreationRedirection,
  block_trio_product_highlight: BlockTrioProductHighlight,
  block_tetris_product_highlight: BlockTetrisProductHighlight,
  block_feature_carousel: BlockFeatureCarousel,
  block_center: BlockCenter,
  block_animated_testimonial: BlockAnimatedTestimonial,
  block_number: BlockNumber,
  block_hubspot_form: BlockHubspotForm,
  block_embed_hubspot_form: BlockEmbedHubspotForm,
  block_filterable_list: BlockFilterableList,
  block_trio_offer: BlockTrioOffer,
  block_hoverable_cards: BlockHoverableCards,
  block_scrollable_cards: BlockScrollableCards,
  block_stretchable_cards: BlockStretchableCards,
  block_animation: BlockAnimation
};

export default SLICES;
