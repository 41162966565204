import { FC, useEffect } from "react";
import { Center, CircularProgress, Container, useBreakpointValue } from "@chakra-ui/react";
import { useHubspotForm, HubSpotFormLocale } from '@aaronhayes/react-use-hubspot-form';
import { buildOptionalFormParams, convertToId, hasLanguage, tweakFormId } from "./utils";
import { useLocation } from "@reach/router";
import { buildPageProperties } from "src/lib/event/properties";
import { logEvent } from "src/lib/analytics";
import { OVERRIDE_CSS } from "./overrideCss";
import { isIOS, isSafari } from "react-device-detect";

// The Portal ID valid for the whole Hubspot project
const HUBSPOT_PORTAL_ID = '26730884';

type BlockHubspotFormIntegrationProps = {
  formId: string;
  formIndex: number;
  inlineMessage?: string;
  redirectUrl?: string;
  embedLayout?: boolean;
  setFormIsAvailableCallback?: (flag: boolean) => void
  setFormIsLoadingCallback?: (flag: boolean) => void
  setFormIsSubmittedCallback?: (flag: boolean) => void
  setFormHasErroredCallback?: (flag: boolean) => void
};

/**
 * This component exists because of the following issue:
 *
 * - formId is required as a param for `useHubspotForm` hook (provided by the external package)
 * - if we don't provide formId, the page can lead to an infinite loop (because of the external package)
 * - formId comes from the CMS
 *   - in our case, Prismic does not allow us to make it a required value
 *   - @see https://prismic.io/blog/required-fields
 * - we could do an early check based on formId and `throw Error()`
 *   - but I don't think one form not being able to render should fail the entire page
 * - we could do an early check based on formId and `return null`
 *   - but we would break the linter rule about hooks `react-hooks/rules-of-hooks`
 *     - "Did you accidentally call a React Hook after an early return?"
 *     - @see https://legacy.reactjs.org/docs/hooks-rules.html#explanation
 * - solution is to perform the check about formId in the parent component
 *   - then we can use `useHubspotForm` hook safely in this child component
 *   - the rule about hooks is not broken
 */
const BlockHubspotFormIntegration: FC<BlockHubspotFormIntegrationProps> = (props) => {
  const {
    formId: formIdTemp,
    formIndex,
    inlineMessage,
    redirectUrl: redirectUrlProp,
    embedLayout,
    setFormIsAvailableCallback,
    setFormIsLoadingCallback,
    setFormIsSubmittedCallback,
    setFormHasErroredCallback
  } = props;

  const formId = tweakFormId(formIdTemp);
  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const location = useLocation();

  // Because Hubspot Form creation is based on HTML id, we try and make it unique
  const inlineMessageId = convertToId(inlineMessage);
  const redirectUrlId = convertToId(redirectUrlProp);
  const stringId = [inlineMessageId, redirectUrlId, formId].filter((id) => !!id).join('-');
  const targetId = `hubspot-form-${stringId}-${formIndex}`;
  const target = `#${targetId}`;

  const locale = hasLanguage('fr') ? HubSpotFormLocale.FRENCH : HubSpotFormLocale.ENGLISH;

  /**
   * To ensure that Segment has time to receive the event,
   * rather than relying on Hubspot API fully for the custom redirect,
   * we use the track callback function
   */
  const optionalFormParamsTemp = buildOptionalFormParams(inlineMessage, redirectUrlProp);
  const { redirectUrl: redirectUrlFromPrismic } = optionalFormParamsTemp;
  const hasRedirectUrlFromPrismic = !!redirectUrlFromPrismic;
  const optionalFormParams = hasRedirectUrlFromPrismic ? {} : optionalFormParamsTemp;

  /**
   * onFormSubmitted($form, data)
   *
   * Callback that executes after form submission.
   * This is for executing actions when the submission is fully complete,
   * such as displaying a confirmation or thank you message, or performing a custom redirect.
   *
   *  The callback's arguments are the form element and an object containing two properties:
   *  redirectUrl: a string containing a redirectUrl, if set.
   *  submissionValues: an object containing the submitted values from the form
   *
   * @see https://legacydocs.hubspot.com/docs/methods/forms/advanced_form_options
   */
  const onFormSubmitted = (_form: any, data: any) => {
    const redirectUrlFromHubspot = data?.redirectUrl;
    const hasRedirectUrlFromHubspot = !!redirectUrlFromHubspot;

    const additionalProperties = embedLayout ? {
      componentName: 'BlockEmbedHubspotForm'
    } : {};

    const event = {
      name: 'Lead Form Submitted',
      properties: {
        ...additionalProperties,
        ...buildPageProperties(location)
      }
    };

    if (hasRedirectUrlFromPrismic) {
      // This is useful for simple scenarii and employees not having access to Hubspot Forms
      logEvent(event, () => window.location.assign(redirectUrlFromPrismic));
    } else if (hasRedirectUrlFromHubspot) {
      // This is useful for more complex scenarii if employees have access to Hubspot Forms
      logEvent(event, () => window.location.assign(redirectUrlFromHubspot));
    } else {
      logEvent(event);
    }

    if (setFormIsSubmittedCallback) {
      setFormIsSubmittedCallback(true);
    }
  };

  const formParams = {
    portalId: HUBSPOT_PORTAL_ID,
    formId,
    formInstanceId: targetId,
    target,
    locale,
    onFormSubmitted,
    ...optionalFormParams
  };

  const { loaded, error, formCreated } = useHubspotForm(formParams);
  const formIsAvailable = loaded && !error && formCreated;

  useEffect(() => {
    if (setFormIsAvailableCallback) {
      setFormIsAvailableCallback(formIsAvailable)
    }
  }, [formIsAvailable]);

  useEffect(() => {
    if (setFormIsLoadingCallback) {
      const isLoading = !loaded && !error;
      setFormIsLoadingCallback(isLoading);
    }
  }, [loaded, error]);

  useEffect(() => {
    if (setFormHasErroredCallback) {
      setFormHasErroredCallback(error);
    }
  }, [error]);

  useEffect(() => {
    if (!embedLayout || !formIsAvailable) return;

    const frameParent = document.getElementById(targetId);

    if (!frameParent) return;

    const startInteractionPolling = (iframeDocument: Document | null) => {
      if (!iframeDocument) {
        return;
      }

      const hsForm = iframeDocument.getElementsByClassName('hs-form-private')?.[0];

      if (hsForm) {
        const input = hsForm.getElementsByTagName('input')?.[0];
        const submit = hsForm.getElementsByClassName('hs-button')?.[0];

        if (input && submit) {
          (input as any).focus();
        } else {
          setTimeout(() => startInteractionPolling(iframeDocument), 50);
        }
      } else {
        setTimeout(() => startInteractionPolling(iframeDocument), 50);
      }
    };

    const startPolling = () => {
      const { children } = frameParent;

      if (children.length === 0) {
        setTimeout(startPolling, 50)
        return;
      }

      const iframe = children[0];

      if (iframe?.tagName !== 'IFRAME') {
        return;
      };

      const iframeCast = iframe as HTMLIFrameElement

      if (!iframeCast) {
        return;
      };

      const classes = iframeCast.classList;
      if (classes && classes.length > 0 && classes[0] === 'hs-form-iframe') {
        const style = document.createElement("style");
        style.appendChild(document.createTextNode(OVERRIDE_CSS));

        if (iframeCast.contentDocument) {
          iframeCast.contentDocument.head.appendChild(style);

          /**
           * Contrary to Google Chrome and Firefox, Safari requires an interaction with the form
           * in order for the height of the form to be calculated and rendered correctly
           * On iOS, the issue appears on all three browsers because of the same rendering engine
           */
          if (isIOS || isSafari) {
            setTimeout(() => startInteractionPolling(iframeCast.contentDocument), 300);
          }
        }
      }
    }

    startPolling();
  }, [formIsAvailable]);

  if (error) {
    return null;
  }

  const additionalProps = isMobile ? { centerContent: true } : {};

  if (!formIsAvailable) {
    const centerProps = embedLayout
      ? {
        sx: {
          position: "absolute",
          left: "calc(50% - 24px)",
          top: "calc(50% - 24px)"
        }
      }
      : { minHeight: "300px", paddingY: "space-128" };

    const circularProgressProps = embedLayout ? {
      color: 'black'
    } : {};

    return (
      <Center flexDirection="column" {...centerProps}>
        <CircularProgress isIndeterminate  {...circularProgressProps} />
      </Center>
    );
  }

  if (embedLayout) {
    return <div id={targetId} />;
  }

  return (
    <Container size="full">
      <Container
        size="block"
        textAlign="center"
        {...additionalProps}
      >
        <div id={targetId} />
      </Container>
    </Container>
  );
};

export default BlockHubspotFormIntegration;
