import { SimpleGrid, Container, ContainerProps } from "@chakra-ui/react";
import { FC } from "react";
import Card from "../atoms/Card";
import CardMarketech from "../atoms/CardMarketech";

const FeatureGrid: FC<
  Gatsby.PageBlockFeatureCarouselFragment & ContainerProps & { isMarketech?: boolean }
> = ({ items, isMarketech, ...rest }) => {
  if (!items) {
    throw Error();
  }

  return (
    <Container size="block" paddingY="0" {...rest}>
      <SimpleGrid columns={items.length} gap="space-32" mx="auto">
        {items?.map((item) => {
          if (!item) {
            return null;
          }

          if (isMarketech) {
            return (
              <CardMarketech
                key={item.rich_description?.text || ""}
                item={item}
              />
            );
          }

          return (
            <Card
              key={item.rich_description?.text || ""}
              item={item}
            />
          );
        })}
      </SimpleGrid>
    </Container>
  );
};

export default FeatureGrid;
