import { FC, useRef, useState } from "react";
import { BlockContainer } from "src/atoms";
import Renderer from "src/lib/renderer";
import { heading2 } from "src/lib/renderer/elements/headings";
import paragraph from "src/lib/renderer/elements/paragraph";
import { Element } from "@prismicio/react";
import Carousel from "./components/Carousel";
import Card from "./atoms/Card";
import CarouselArrow from "./atoms/CarouselArrow";
import {
  Box,
  HStack,
  Flex,
  Grid,
  Spacer,
  VStack,
  useBreakpointValue
} from "@chakra-ui/react";
import { getMobileLeftScrollValue, getTemplateColumns } from "./utils";
import { clamp } from "../utils";

const DEFAULT_INDEX_IF_NO_CMS_VALUE = -1;

const BlockStretchableCards: FC<Gatsby.PageBlockStretchableCardsFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();

  const {
    content,
    default_stretched_image_index: defaultStretchedImageIndex
  } = primary;

  const numberItems = items?.length || 0;
  const defaultImageIndex = !defaultStretchedImageIndex || defaultStretchedImageIndex === 0
    ? DEFAULT_INDEX_IF_NO_CMS_VALUE
    : clamp(0, numberItems - 1, DEFAULT_INDEX_IF_NO_CMS_VALUE, defaultStretchedImageIndex - 1);

  const childRef = useRef<HTMLDivElement>();

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const [shouldShowArrows, setShouldShowArrows] = useState(false);
  const [prevArrowActive, setPrevArrowActive] = useState(false);
  const [nextArrowActive, setNextArrowActive] = useState(false);

  const [expandedItemsIndexes, setExpandedItemsIndexes] = useState<number[]>([defaultImageIndex]);

  const setItemsIndexes = (index: number) => {
    const newExpandedItemsIndexes = [index];
    setExpandedItemsIndexes(newExpandedItemsIndexes);
  };

  const resetItemsIndexes = () => {
    setExpandedItemsIndexes([defaultImageIndex]);
  };

  const expandedItemIndex = expandedItemsIndexes?.[0] >= 0 ? expandedItemsIndexes?.[0] : defaultImageIndex;
  const sideScroll = (direction: string) => {
    if (childRef.current) {
      const left = getMobileLeftScrollValue(direction, numberItems, childRef.current.scrollLeft, window.innerWidth);

      childRef.current.scrollBy({
        left,
        behavior: "smooth",
      });
    }
  };

  return (
    <BlockContainer>
      <VStack w="full">
        <Box
          textAlign="left"
          w="full"
        >
          <Renderer
            field={content}
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    fontSize: { base: "font-42", md: "48px" },
                    lineHeight: { base: 1.07, md: 1.2 },
                    marginBottom: "space-16"
                  },
                }),
              [Element.paragraph]: (args) =>
                paragraph({
                  ...args,
                  overrideProps: {
                    display: "none"
                  },
                }),
            }}
          />
          <Flex>
            <Box
              width={{ base: "60%", md: "full" }}
            ><Renderer
                field={content}
                overrides={{
                  [Element.heading1]: (args) =>
                    heading2({
                      ...args,
                      overrideProps: {
                        display: "none"
                      },
                    }),
                  [Element.heading2]: (args) =>
                    heading2({
                      ...args,
                      overrideProps: {
                        display: "none"
                      },
                    }),
                  [Element.heading3]: (args) =>
                    heading2({
                      ...args,
                      overrideProps: {
                        display: "none"
                      },
                    }),
                  [Element.paragraph]: (args) =>
                    paragraph({
                      ...args,
                      overrideProps: {
                        fontSize: { base: "font-16", md: "font-20" },
                        lineHeight: "base",
                      },
                    }),
                }}
              /></Box>
            <Spacer
              display={{ base: "block", md: "none" }}
            />
            <Box
              display={{ base: "block", md: "none" }}
            >
              {shouldShowArrows && (
                <HStack
                  spacing="space-8"
                  w="full"
                  justifyContent="center"
                >
                  <CarouselArrow
                    onClick={() => sideScroll("prev")}
                    disabled={!prevArrowActive}
                    isPrev={true}
                  />
                  <CarouselArrow
                    onClick={() => sideScroll("next")}
                    disabled={!nextArrowActive}
                    isPrev={false}
                  />
                </HStack>
              )}
            </Box>
          </Flex>
        </Box>
        <Grid
          width='full'
          display={{ base: "none", md: "grid" }}
          gridTemplateColumns={
            {
              base: getTemplateColumns(expandedItemIndex, numberItems, "base"),
              lg: getTemplateColumns(expandedItemIndex, numberItems, "lg"),
              "xl": getTemplateColumns(expandedItemIndex, numberItems, "xl"),
              "2xl": getTemplateColumns(expandedItemIndex, numberItems, "2xl"),

            }
          }
          gridTemplateRows="1"
          gridColumnGap="space-16"
          transition="grid linear 750ms"
        >
          {items?.length ? items.map((item, index: number) => {
            if (!item) {
              return null;
            }

            const backgroundImageUrl = item.background_image?.document?.data?.image_file?.gatsbyImageData?.images?.fallback?.src;

            return <Card
              item={item}
              index={index}
              key={`${item.title}-${item.description}-${backgroundImageUrl}`}
              expandedItemsIndexes={expandedItemsIndexes}
              onMouseEnter={() => { if (!isMobile) setItemsIndexes(index) }}
              onMouseLeave={() => { if (!isMobile) resetItemsIndexes() }}
            />;
          }) : null}
        </Grid>
        {items ? (
          <Carousel
            ref={childRef}
            items={items}
            primary={primary}
            display={{ base: "flex", md: "none" }}
            setShouldShowArrows={setShouldShowArrows}
            setPrevArrowActive={setPrevArrowActive}
            setNextArrowActive={setNextArrowActive}
          />
        ) : null}
      </VStack>
    </BlockContainer>
  );
};

export default BlockStretchableCards;
