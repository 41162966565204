import {
  Box,
  VStack,
  Text,
  HStack,
  chakra,
  Spacer,
  Button,
} from "@chakra-ui/react";
import { FC, Fragment, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { PrismicLink } from "src/atoms";

const DEFAULT_ITEM_DISPLAYED_COUNT = 4;
const INCREMENT_ITEM_DISPLAYED_COUNT = 4;

const MobileStack: FC<Gatsby.PageBlockPersonaFragment> = ({ items }) => {
  const [itemDisplayedCount, setItemDisplayedCount] = useState(DEFAULT_ITEM_DISPLAYED_COUNT);
  if (!items) return null;

 const chunkItems = items.slice(0, itemDisplayedCount);

  return (
    <VStack
      spacing="space-16"
      w="full"
      align="stretch"
      display={{ md: "none" }}
    >
      {chunkItems?.map((item) => {
        if (!item) return null;

        const isLink = Boolean(item?.link?.url);

        return (
          <Box
            key={item.label}
            bg="greyLight-3"
            borderRadius="lg"
            position="relative"
            overflow="hidden"
            pl="25%"
            sx={{
              ":hover": {
                ".element-cta-icon": {
                  transform: (theme) => `translateX(${theme.space[1]})`,
                },
              },
            }}
          >
            <Box
              position="absolute"
              top="0"
              left="0"
              h="full"
              w="25%"
              sx={{
                ".gatsby-image-wrapper": {
                  h: "inherit",
                },
                img: {
                  borderLeftRadius: "lg", //required to override gatsby image default css
                  objectPosition: "top",
                },
              }}
            >
              <Box
                as={GatsbyImage}
                image={item.image?.document?.data?.image_file?.gatsbyImageData}
                alt={item.image?.document?.data?.image_file?.alt ?? ""}
                sizes="25vw"
              />
            </Box>
            <HStack py="space-24" pl="space-16" pr="space-8">
              <Text as="div" textAlign="left" fontWeight="medium">
                {item.label}
              </Text>
              {isLink && (
                <Fragment>
                  <Spacer />
                  <chakra.span
                    transition="transform .2s ease-out"
                    marginLeft="auto"
                    w="30px"
                    className="element-cta-icon"
                  >
                    →
                  </chakra.span>
                  <Box
                    as={PrismicLink}
                    link={item.link}
                    position="absolute"
                    w="full"
                    h="full"
                    top="0"
                    left="0"
                  />
                </Fragment>
              )}
            </HStack>
          </Box>
        );
      })}
      {itemDisplayedCount < items.length ? (
        <Button
          bg="greyLight-3"
          borderRadius="lg"
          p="space-14"
          fontWeight="medium"
          fontSize="font-14"
          lineHeight="none"
          w="full"
          color="text-primary"
          _hover={{}}
          onClick={() => setItemDisplayedCount(itemDisplayedCount + INCREMENT_ITEM_DISPLAYED_COUNT)}
        >
          +
        </Button>
      ) : null}
    </VStack>
  );
};

export default MobileStack;
