import { Box, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { Serializer } from "src/lib/renderer/types";
import { ReactComponent as Magnifier } from "../assets/magnifier.svg";

const BlockQuote: Serializer = (args) => {
  const [title, ...paragraphs] = (args.element.text as string).split("\n");

  const [_headEmoji, ...restTitle] = title?.trim().split(" ") ?? [];

  return (
    <Box
      backgroundColor="grey-4"
      className="blog-blockquote"
      padding="space-32"
      borderRadius="md"
      key={args.key}
    >
      <Heading as="h3" variant="subtitle-large" marginBottom="space-16">
        <Box
          display="inline-flex"
          background="white"
          marginRight="space-16"
          width="3.5rem"
          height="3.5rem"
          alignItems="center"
          justifyContent="center"
          borderRadius="lg"
        >
          <Icon as={Magnifier} boxSize="1.5rem" />
        </Box>

        {restTitle?.join(" ")}
      </Heading>

      <VStack align="stretch">
        {paragraphs.map((paragraph, index) => (
          <Text key={index}>{paragraph}</Text>
        ))}
      </VStack>
    </Box>
  );
};

export default BlockQuote;
