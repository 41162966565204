import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { Card, ShiIcon } from "src/atoms";
import { ShiIconName } from "src/atoms/ShiIcon";
import Renderer from "src/lib/renderer";
import { PriceUnderline } from "src/templates/Page/slices/PricingPlans/components/PlanCard/styles";

interface PricingDisplayProps {
  display: Gatsby.CollectionPricingsDisplayFragment;
}
const PricingDisplay: FC<PricingDisplayProps> = ({ display }) => {
  return (
    <Card
      marginTop={{
        md: display.primary?.highlight ? "-space-32" : undefined,
      }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      hoverable={false}
      key={display.primary?.title}
    >
      <Box
        sx={{
          "> .chakra-image__placeholder, > .chakra-image": {
            marginY: "space-32",
          },
          "> .chakra-stack": {
            marginTop: "space-4",
          },
        }}
      >
        <Text
          as="div"
          sx={{
            strong: {
              fontWeight: "medium",
            },
          }}
          marginY="space-24"
        >
          {display.primary?.title}
        </Text>

        <Box>
          <Flex width="100%" justify="center" alignItems="flex-start">
            <Text as="span" color="text-secondary">
              {display.primary?.tax}
            </Text>

            <Box width="fit-content" fontSize="font-34">
              <Text fontWeight="medium">{display.primary?.price}</Text>

              <PriceUnderline
                color={display.primary?.price_color || "yellow-3"}
              />
            </Box>

            {display.primary?.recurrence && (
              <Text
                as="span"
                display="inline-block"
                marginLeft="space-8"
                alignSelf="flex-end"
                color="text-secondary"
              >
                {display.primary?.recurrence}
              </Text>
            )}
          </Flex>
        </Box>

        {display.primary?.crossed_out_price && (
          <Text color="greyLight-3" textDecoration="line-through">
            {display.primary?.crossed_out_price}
          </Text>
        )}

        {display.primary?.price_description && (
          <Text
            as="div"
            marginY="space-24"
            sx={{
              ".chakra-text": {
                color: "text-primary",
                fontSize: "font-16",
              },
            }}
          >
            <Renderer field={display.primary?.price_description} />
          </Text>
        )}

        {display.primary?.image?.document?.__typename ===
          "PrismicElementImage" && (
          <Box marginTop="space-48" marginBottom="space-32" maxWidth="215px">
            <GatsbyImage
              image={
                display.primary?.image?.document?.data?.image_file
                  ?.gatsbyImageData
              }
              alt={
                display.primary?.image?.document?.data?.image_file?.alt ?? ""
              }
            />
          </Box>
        )}

        {display.items &&
          display.items.length > 0 &&
          display.items.map((item) => {
            if (!item) {
              return null;
            }

            const { feature, strikethrough, icon_name } = item;

            return (
              <VStack
                alignItems="start"
                spacing="space-8"
                textAlign="left"
                key={feature?.text}
              >
                <HStack alignItems="start">
                  <ShiIcon
                    name={(icon_name as ShiIconName) || "check"}
                    color={strikethrough ? "text-lighter" : "text-secondary"}
                    fontSize="font-16"
                    marginRight="space-4"
                    marginTop="2px"
                  />

                  <Text
                    as="div"
                    sx={{
                      ".chakra-text": {
                        color: strikethrough ? "text-lighter" : "text-primary",
                        fontSize: "font-14",
                        textDecoration: strikethrough
                          ? "line-through"
                          : undefined,
                      },
                    }}
                  >
                    <Renderer field={feature} />
                  </Text>
                </HStack>
              </VStack>
            );
          })}
      </Box>

      <Box marginTop="space-32">
        <Renderer field={display.primary?.buttons} />
      </Box>
    </Card>
  );
};

export default PricingDisplay;
