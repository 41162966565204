import { createContext } from "react";

export type FilterableContextType = {
  query: string;
  category: string;
};

export const FilterableContextDefaultValue: FilterableContextType = {
  query: "",
  category: "",
};

const FilterableContext = createContext<{
  filters: FilterableContextType,
  setFilters: (filters: FilterableContextType) => void
}>({
  filters: FilterableContextDefaultValue,
  setFilters: () => FilterableContextDefaultValue
});

export default FilterableContext;
