import { Box, Flex, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { ShiIcon } from "src/atoms";
import ShiButton from "src/atoms/ShiButton";

const Card: FC<{
  isDisplayed: boolean;
  item: NonNullable<Gatsby.PageBlockFilterableListFragment["items"]>[number];
}> = ({
  isDisplayed,
  item
}) => {
  if (!item) {
    return null;
  }

  const {
    icon,
    categories,
    primary_text: title,
    tags,
    cta_label,
    link,
    created_at,
    updated_at,
  } = item;

  const imageFile = icon?.document?.data?.image_file;
  const category = categories?.raw?.[0]?.text;

  const createdAt = new Date(created_at || new Date());
  const updatedAt = updated_at ? new Date(updated_at) : null;
  const beenUpdated = updatedAt && updatedAt > createdAt;
  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
  };
  const thisYear = new Date().getFullYear();
  if ((beenUpdated && updatedAt.getFullYear() !== thisYear) || (!beenUpdated && createdAt.getFullYear() !== thisYear)) {
    dateOptions.year = "numeric";
  }

  return (
    <VStack
      alignItems="flex-start"
      border="1px solid"
      borderColor="#E0E0E0"
      borderRadius="2xl"
      p={{ base: "space-24", lg: "8" }}
      spacing="6"
      display={isDisplayed ? "flex" : "none"}
      _hover={{
        lg: {
          borderColor: "grey-0",
          ".tags, .date": {
            display: "none",
          },
          ".cta": {
            display: "flex",
          }
        },
      }}
    >
      <HStack spacing="2">
        {imageFile ? <Box
          as={GatsbyImage}
          borderRadius="full"
          image={imageFile.gatsbyImageData}
          alt={imageFile.alt ?? ""}
          maxH="34px"
          maxW="34px"
        /> : null}

        <Text
          fontFamily="body"
          fontSize="font-15"
          lineHeight="none"
        >
          {category}
        </Text>
      </HStack>

      <Text
        fontFamily="body"
        fontSize="font-18"
        lineHeight="tall"
      >
        {title}
      </Text>

      <Spacer
        flex="1"
        display={{ base: "none", lg: "flex" }}
      />

      {tags ? (
        <Flex
          className="tags"
          display={{ base: "none", lg: "flex" }}
          alignItems="flex-start"
          flexWrap="wrap"
          gridGap="2"
        >
          {tags.raw.map((tag: any) => (
            <Text
              key={tag.text}
              as="span"
              bg="#F5F5F5"
              borderRadius="60px"
              color="grey.700"
              fontFamily="body"
              fontSize="font-12"
              fontWeight="medium"
              lineHeight="1.8"
              px="5"
              py="2"
              textTransform="uppercase"
              whiteSpace="nowrap"
            >
              {tag.text}
            </Text>
          ))}
        </Flex>
      ) : null}

      <ShiButton
        as={Link}
        w="full"
        className="cta"
        variant="primary"
        to={link.url!}
        display={{ base: "flex", lg: "none" }}
        hasArrow={false}
        cursor="pointer"
        gridGap="space-8"
      >
        <ShiIcon
          name="download"
        />

        {cta_label}
      </ShiButton>

      {beenUpdated ? (
        <HStack
          className="date"
          w="full"
          justifyContent={{ base: "center", lg: "flex-start" }}
        >
          <ShiIcon
            name="repeat"
            sx={{
              transform: `scale(1)`,
              path: { fill: 'grey.600' }
            }}
          />

          <Text
            color="#888785"
            fontFamily="body"
            fontSize="font-15"
            lineHeight="none"
          >
            Mis à jour le {updatedAt.toLocaleDateString('fr', dateOptions)}
          </Text>
        </HStack>
      ) : (
        <HStack
          className="date"
          w="full"
          justifyContent={{ base: "center", lg: "flex-start" }}
        >
          <ShiIcon
            name="edit"
            sx={{
              transform: `scale(1)`,
              path: { fill: 'grey.600' }
            }}
          />

          <Text
            color="#888785"
            fontFamily="body"
            fontSize="font-15"
            lineHeight="none"
          >
            Publié le {createdAt.toLocaleDateString('fr', dateOptions)}
          </Text>
        </HStack>
      )}
    </VStack>
  );
};

export default Card;
