import { ButtonProps, Button } from "@chakra-ui/react";
import { FC } from "react";
import { ShiIcon } from "src/atoms";

const CarouselArrow: FC<ButtonProps & { light?: boolean, isPrev: boolean }> = ({
  light,
  isPrev,
  ...props
}) => {
  const prefix = isPrev ? "left" : "right";
  const suffix = props.disabled ? "grey" : "white";

  return (
    <Button
      bg={light ? "greyLight-3" : "grey-0"}
      color={light ? "text-primary" : "white"}
      border="1px solid"
      borderColor="grey-0"
      fontSize="font-24"
      fontWeight="400"
      lineHeight="base"
      paddingX="space-24"
      paddingY="space-24"
      borderRadius="48px"
      minWidth="48px"
      maxWidth="48px"
      minHeight="48px"
      maxHeight="48px"
      fontFamily="heading"
      _hover={
        props.disabled
          ? {}
          : {
            bg: light ? "bg-primary-active" : "grey-1",
            color: light ? "white" : "white"
          }
      }
      _disabled={{
        bg: "white",
        color: "text-primary",
        opacity: "0.6",
        cursor: "default",
      }}
      {...props}
    >
      <ShiIcon
        className="arrow-left"
        h="12px"
        w="12px"
        name={`arrow${prefix}${suffix}`}
      />
    </Button>
  );
};

export default CarouselArrow;
